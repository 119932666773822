<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      show-date
      :date-validation="false"
      :is-downloading="isListExport"
      :export-options="['excel', 'csv', 'pdf']"
      @isExport="exportList"
      @filterOption="onFiltersChange"
    >
      <!-- add Filters -->
      <template #addFilters>
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="100"
          content-class="filterMenDropDown"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card>
              <v-text-field
                class="filterDropDown"
                dense
                v-bind="attrs"
                label="Filters"
                :value="showSelectedFilters"
                readonly
                v-on="on"
              />
            </v-card>
          </template>
          <v-card class="my-0 py-0">
            <v-list class="my-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4>Filters</h4>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    color="primary"
                    text
                    small
                    @click="clearFilters"
                  >
                    Clear
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
              <v-list-item
                v-for="(item, i) in filterOptions"
                :key="i"
              >
                <div>
                  <v-list-item-action class="py-0 my-0">
                    <v-checkbox
                      ref="myFilterCheck"
                      v-model="selectedFilter"
                      :label="item.name.replace(/_/g, ' ')"
                      :value="item.id"
                      hide-details
                      :ripple="false"
                      @change="
                        onFilterChange(
                          item.id,
                          selectedFilter.includes(item.id)
                        )
                      "
                    />
                  </v-list-item-action>
                  <v-list-item-content
                    v-if="
                      item.id == 'distance' && selectedFilter.includes(item.id)
                    "
                    class="py-0 my-0"
                  >
                    <div class="distance-Options">
                      <div>
                        <v-select
                          v-model="isDistance.operator"
                          :items="[
                            // { value: '=', title: '=' },
                            { value: '>', title: '>' },
                            { value: '<', title: '<' },
                          ]"
                          item-text="title"
                          item-value="value"
                          class="py-0 pt-2"
                          @change="onDistanceChange"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="isDistance.value"
                          :label="$t(item.name)"
                          type="number"
                          min="0"
                          max="100"
                          class="py-0 pt-2"
                          @change="onDistanceChange"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content
                    v-if="
                      item.id == 'journey_time' &&
                        selectedFilter.includes(item.id)
                    "
                    class="py-0 my-0"
                  >
                    <div class="duration-Options">
                      <div>
                        <v-select
                          v-model="isDuration.operator"
                          :items="[
                            { value: '=', title: '=' },
                            { value: '>', title: '>' },
                            { value: '<', title: '<' },
                          ]"
                          item-text="title"
                          item-value="value"
                          class="py-0 pt-2"
                          @change="onDurationChange"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="isDuration.value"
                          :label="$t(item.name)"
                          type="number"
                          min="0"
                          max="100"
                          class="py-0 pt-2"
                          @change="onDurationChange"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <!-- add Filters -->

      <!-- Journey Tags -->
      <template #description>
        <summary-detail
          v-if="summary.length > 0"
          :is-loading="isLoading"
          :items="summaryData"
          @exportSummary="exportSummary"
        />
      </template>
      <!-- journey Tags -->

      <!-- Action Modals -->
      <!-- <template #actionModals> </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    summaryDetail: () => import("../utils/summaryDetail.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      customFilter: {},

      filterMenu: false,
      selectedFilter: [],

      isDistance: {},
      isDuration: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "journeyReports/getLoading",
      meta: "journeyReports/meta",
      list: "journeyReports/getJourneyList",
      //
      summary: "journeyReports/getSummary",
      //
      isVehicleList: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      //
      isListExport: "journeyReports/getIsListExport",

      //
      // for tenant_tech
      tenantsList: "tenantsManagement/getTenantsList",
    }),
    getVehilceTech() {
      const row = this.tenantsList.find((r) => r.id == this.options.tenant_id);
      return row?.vehicle_tech || null;
    },
    _headers() {
      let vTech = this.getVehilceTech;
      // Condition only for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature !== "fleet") {
        vTech = this.authUser.user_tech;
      }

      const heads = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "vehicle_id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "vehicle_variant_name",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
        },
        {
          text: this.$t("start_date"),
          align: "left",
          sortable: false,
          value: "start_date",
        },
        {
          text: this.$t("start_time"),
          align: "left",
          sortable: false,
          value: "start_time",
        },
        {
          text: this.$t("end_date"),
          align: "left",
          sortable: false,
          value: "end_date",
        },
        {
          text: this.$t("end_time"),
          align: "left",
          sortable: false,
          value: "end_time",
        },
        {
          text: this.$t("journey_time"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("distance"),
          align: "left",
          sortable: false,
          value: "distance",
        },
        {
          text: this.$t("avg_speed"),
          align: "left",
          sortable: false,
          value: "avg_speed",
        },
        {
          text: this.$t("max_speed"),
          align: "left",
          sortable: false,
          value: "max_speed",
        },
        ...(vTech == "ev"
          ? [
              {
                text: this.$t("battery_consumed"),
                align: "left",
                sortable: false,
                value: "battery_consumed",
              },
            ]
          : []),
        ...(vTech == "ice"
          ? [
              {
                text: this.$t("fuel_consumption"),
                align: "left",
                sortable: false,
                value: "fuel_consumption",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return heads.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    //
    filterOptions() {
      const arr = [
        {
          id: "distance",
          name: "distance",
        },
        {
          id: "journey_time",
          name: "journey_time_",
        },
      ];

      return arr;
    },
    showSelectedFilters() {
      const arr = [];
      this.selectedFilter.forEach((el) => {
        arr.push(el);
      });
      return arr.length > 0
        ? arr.map((r) => r.replace("_", " ")).join(", ")
        : "";
    },
    //
    filterNames() {
      if (this.authUser.isAdmin) {
        return ["vehicle"];
      } else {
        return ["vehicle"];
      }
    },
    //
    summaryData() {
      let vTech = this.getVehilceTech;
      // Condition only for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature !== "fleet") {
        vTech = this.authUser.user_tech;
      }

      const arr = [];

      this.summary.forEach((el) => {
        const param = {
          name: el?.vehicle_variant_name,
          imei: el?.imei,
          total_journey_time: el?.total_journey_time,
          total_distance: el?.total_distance,
          avg_speed: el?.total_avg_speed,
          max_speed: el?.max_max_speed,
        };
        if (vTech == "ev") {
          param.battery_consumed = el?.battery_consumed;
        }
        if (vTech == "ice") {
          param.fuel_consumption = el?.fuel_consumption;
        }
        arr.push(param);
      });

      return arr;
    },
  },
  watch: {
    "options.vehicle_ids": {
      handler: function (v) {
        if (v.length < 0 || v.length == 0) {
          this.$store.commit("journeyReports/SET_LIST", []);
          this.$store.commit("journeyReports/SET_SUMMARY", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  beforeMount() {
    this.selectedFilter = this.$route?.query.filters
      ? this.$route?.query.filters
      : [];
    if (this.selectedFilter.includes("distance")) {
      this.isDistance.operator = this.$route.query.distance_operator;
      this.isDistance.value = this.$route.query.distance_value;

      this.onDistanceChange(false);
    }
    if (this.selectedFilter.includes("journey_time")) {
      this.isDuration.operator = this.$route.query.duration_operator;
      this.isDuration.value = this.$route.query.duration_value;

      this.onDurationChange(false);
    }
  },
  beforeDestroy() {
    const params = {
      name: "journey_reports",
      filters: {
        filters: this.selectedFilter,
        ...this.options,
        ...this.customFilter,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },

  methods: {
    async fetchData() {
      if (this.options?.vehicle_ids?.length > 0) {
        const payload = {
          dateType: this.options.dateType,
          date: this.options.date,
          vehicle_ids: this.options.vehicle_ids,
          ...this.customFilter,
        };
        await this.$store.dispatch("journeyReports/summary", payload);
        const params = {
          ...this.options,
          ...this.customFilter,
        };
        await this.$store.dispatch("journeyReports/list", params).then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
      }
    },
    //
    exportList(val) {
      const module_name = "journeyReports/exportList";
      this.downloadFile(module_name, val);
    },
    exportSummary(val) {
      const module_name = "journeyReports/exportSummary";
      this.downloadFile(module_name, val);
    },

    //
    downloadFile(mod, val) {
      const params = {
        type: val,
        dateType: this.options.dateType,
        date: this.options.date,
        vehicle_ids: this.options.vehicle_ids,
        ...this.customFilter,
      };
      this.$store
        .dispatch(mod, params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("download_successfully")
          );
        })
        .catch(() => {
          this.$store.dispatch("alerts/error", this.$t("file_corrupted"));
        });
    },
    //
    onDistanceChange(fetch = true) {
      if (this.isDistance.operator && this.isDistance.value) {
        this.customFilter.distance_operator = this.isDistance.operator;
        this.customFilter.distance_value = this.isDistance.value;
        if (fetch) {
          this.options.page = 1;
          this.fetchData();
        }
      }
    },
    onDurationChange(fetch = true) {
      if (this.isDuration.operator && this.isDuration.value) {
        this.customFilter.duration_operator = this.isDuration.operator;
        this.customFilter.duration_value = this.isDuration.value;
        if (fetch) {
          this.options.page = 1;
          this.fetchData();
        }
      }
    },
    onFilterChange(i, snd) {
      let v = this.selectedFilter;

      if (!v.includes("distance")) {
        this.isDistance = {};
        this.customFilter.distance_operator = null;
        this.customFilter.distance_value = null;
      }
      if (!v.includes("journey_time")) {
        this.isDuration = {};
        this.customFilter.duration_operator = null;
        this.customFilter.duration_value = null;
      }

      if (snd == false) {
        this.options.page = 1;
        this.fetchData();
      }
    },

    clearFilters() {
      this.selectedFilter = [];
      this.isDistance = {};
      this.customFilter.distance_operator = null;
      this.customFilter.distance_value = null;
      this.isDuration = {};
      this.customFilter.duration_operator = null;
      this.customFilter.duration_value = null;
      this.filterMenu = false;
      // this.onTimeIntervalChange();
      this.fetchData();
    },
  },
};
</script>
<style lang="sass" scoped>
// .filterDropDown
  // padding: 0 !important
  // margin: 0 !important
:deep .v-input
  text-transform:  capitalize !important

.filterDropDown
  :deep input
    text-transform:  capitalize !important

.distance-Options , .duration-Options
  display: flex
  gap: 1rem
  div:first-child
    flex-basis: 25%
  div:last-child
    flex-basis: 75%
</style>
