var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ListingLayout',{attrs:{"is-loading":_vm.isLoading,"meta":_vm.meta,"headers":_vm._headers,"items":_vm.list,"filter-names":_vm.filterNames,"show-date":"","date-validation":false,"is-downloading":_vm.isListExport,"export-options":['excel', 'csv', 'pdf']},on:{"isExport":_vm.exportList,"filterOption":_vm.onFiltersChange},scopedSlots:_vm._u([{key:"addFilters",fn:function(){return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"content-class":"filterMenDropDown","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',[_c('v-text-field',_vm._g(_vm._b({staticClass:"filterDropDown",attrs:{"dense":"","label":"Filters","value":_vm.showSelectedFilters,"readonly":""}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('v-card',{staticClass:"my-0 py-0"},[_c('v-list',{staticClass:"my-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v("Filters")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":_vm.clearFilters}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-divider'),_c('v-list',_vm._l((_vm.filterOptions),function(item,i){return _c('v-list-item',{key:i},[_c('div',[_c('v-list-item-action',{staticClass:"py-0 my-0"},[_c('v-checkbox',{ref:"myFilterCheck",refInFor:true,attrs:{"label":item.name.replace(/_/g, ' '),"value":item.id,"hide-details":"","ripple":false},on:{"change":function($event){_vm.onFilterChange(
                        item.id,
                        _vm.selectedFilter.includes(item.id)
                      )}},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1),(
                    item.id == 'distance' && _vm.selectedFilter.includes(item.id)
                  )?_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"distance-Options"},[_c('div',[_c('v-select',{staticClass:"py-0 pt-2",attrs:{"items":[
                          // { value: '=', title: '=' },
                          { value: '>', title: '>' },
                          { value: '<', title: '<' },
                        ],"item-text":"title","item-value":"value"},on:{"change":_vm.onDistanceChange},model:{value:(_vm.isDistance.operator),callback:function ($$v) {_vm.$set(_vm.isDistance, "operator", $$v)},expression:"isDistance.operator"}})],1),_c('div',[_c('v-text-field',{staticClass:"py-0 pt-2",attrs:{"label":_vm.$t(item.name),"type":"number","min":"0","max":"100"},on:{"change":_vm.onDistanceChange},model:{value:(_vm.isDistance.value),callback:function ($$v) {_vm.$set(_vm.isDistance, "value", $$v)},expression:"isDistance.value"}})],1)])]):_vm._e(),(
                    item.id == 'journey_time' &&
                      _vm.selectedFilter.includes(item.id)
                  )?_c('v-list-item-content',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"duration-Options"},[_c('div',[_c('v-select',{staticClass:"py-0 pt-2",attrs:{"items":[
                          { value: '=', title: '=' },
                          { value: '>', title: '>' },
                          { value: '<', title: '<' },
                        ],"item-text":"title","item-value":"value"},on:{"change":_vm.onDurationChange},model:{value:(_vm.isDuration.operator),callback:function ($$v) {_vm.$set(_vm.isDuration, "operator", $$v)},expression:"isDuration.operator"}})],1),_c('div',[_c('v-text-field',{staticClass:"py-0 pt-2",attrs:{"label":_vm.$t(item.name),"type":"number","min":"0","max":"100"},on:{"change":_vm.onDurationChange},model:{value:(_vm.isDuration.value),callback:function ($$v) {_vm.$set(_vm.isDuration, "value", $$v)},expression:"isDuration.value"}})],1)])]):_vm._e()],1)])}),1)],1)],1)]},proxy:true},{key:"description",fn:function(){return [(_vm.summary.length > 0)?_c('summary-detail',{attrs:{"is-loading":_vm.isLoading,"items":_vm.summaryData},on:{"exportSummary":_vm.exportSummary}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }